.loader {
	width: 48px;
	height: 48px;
	border: 5px solid #0267ff;
	border-right-color: transparent !important;
	border-bottom-color: transparent !important;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	margin-right: 6px;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.container {
	position: relative;

	.loader-overlay {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.3);
	}

	.loader {
		position: absolute;
		left: calc(50% - 24px);
		top: calc(50% - 24px);
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';